import { $$, on, registerStartup, type EventOf } from 'lib/utils';

registerStartup(() => {
  on('change', 'input[type=checkbox],input[type=radio],select', handleChange);
  $$<HTMLFieldSetElement>('[data-enable-on]').forEach(initializeFieldSet);
});

function negate(elem: HTMLElement, enabled: boolean) {
  return elem.dataset.negate != null ? !enabled : enabled;
}

function handleChange(ev: EventOf<HTMLInputElement>) {
  const elem = ev.currentTarget;
  if (!elem.name) return;

  elem.form?.querySelectorAll<HTMLFieldSetElement>(`[data-enable-on="${elem.name}"]`).forEach((fs) => {
    const enableValues = fs.dataset.enableValue?.split(',');
    const selected = negate(fs, enableValues ? enableValues.includes(elem.value) : elem.checked);
    fs.disabled = !selected;
    fs.classList.toggle('active', selected);
    setTimeout(() => fs.classList.toggle('show', selected), 0);
  });
}

function initializeFieldSet(fs: HTMLFieldSetElement) {
  const enableValues = fs.dataset.enableValue?.split(',');
  const elems = ((elems) => {
    if (elems instanceof HTMLInputElement) return [elems];
    if (elems instanceof RadioNodeList) return Array.from(elems) as HTMLInputElement[];
    return [];
  })((fs.form || fs.closest('form'))?.elements.namedItem(fs.dataset.enableOn!));
  const selected = enableValues
    ? elems.some((elem) => negate(fs, elem.checked && enableValues.includes(elem.value)))
    : elems.some((elem) => negate(fs, elem.checked));
  fs.disabled = !selected;
  fs.classList.toggle('active', selected);
  fs.classList.toggle('show', selected);
}
